<template>
    <b-list-group-item button :class="classObject" v-on:click="onClickSideBarItem(to)">
        <div style="width: 30px;">
            <font-awesome-icon v-if="icon" :icon="icon" size="sm" class="st-sidebaricon"/>
        </div>
        {{ name }}
    </b-list-group-item>
</template>

<script>

export default {
    name: "SideBarItem",
    props: ['itemclass', 'name', 'icon', 'to'],
    computed: {
        classObject: function () {
            return [
                {'d-flex': true},
                this.itemclass,
                {'st-sidebaritem': !this.itemclass},
                {'active': this.$route.path === this.to},
            ];
        },
    },
    methods: {
        onClickSideBarItem(to) {
            if (this.$route.path !== to) {
                this.$router.push(to);
            }
        }
    }
}
</script>

<style scoped>

</style>
