<template>
    <b-list-group>
        <b-list-group-item class="d-flex st-sidebartitle">
            {{ name }}
        </b-list-group-item>
    </b-list-group>
</template>

<script>

export default {
    name: "SideBarTitle",
    props: ['name', 'icon']
}
</script>

<style scoped>

</style>
