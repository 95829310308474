<template>
    <div>
        <b-list-group-item button :class="{'d-flex': true, 'st-sidebardropdown': true}" role="tab"
                           v-on:click="visible=!visible">
            <div style="width: 30px;">
                <font-awesome-icon v-if="icon" :icon="icon" size="sm" class="st-sidebaricon"/>
            </div>
            {{ name }}
            <div class="ml-auto">
                <font-awesome-icon v-if="visible" :icon="['fas', 'chevron-down']" size="sm" class="st-sidebardropdownicon"/>
                <font-awesome-icon v-else :icon="['fas', 'chevron-left']" size="sm" class="st-sidebardropdownicon"/>
            </div>
        </b-list-group-item>
        <b-collapse v-model="visible" accordion="sidebar-accordion" role="tabpanel">
            <template v-for="(child,index) in children">
                <SideBarItem :key="'child-' + child.type + '-' + index" itemclass="st-sidebardropdownitem" :name="child.name" :icon="child.icon" :to="child.to"/>
            </template>
        </b-collapse>
    </div>
</template>

<script>
import SideBarItem from "@/components/sidebar/SideBarItem";

export default {
    name: "SideBarDropDown",
    props: ['name', 'icon', 'route', 'children'],
    components: {
        SideBarItem,
    },
    data() {
        return {
            visible: false,
        }
    },
    watch: {
        $route(to) {
            this.visible = (to.path.indexOf(this.route) === 0);
        }
    },
    created() {
        this.visible = (this.$route.path.indexOf(this.route) === 0);
    }
}
</script>

<style scoped>

</style>
