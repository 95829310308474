<template>
    <div class="backdrop">
        <div class="container h-100">
            <div class="row h-100 justify-content-center align-items-center">
                <b-card no-body class="shadow login">
                    <b-card-header style="text-align: center; font-weight: bold; font-size: larger;">
                        {{ toolName }}
                    </b-card-header>
                    <b-card-footer>
                        <b-form v-on:keyup.enter="login">
                            <b-input-group prepend="Email">
                                <b-form-input type="email" ref="email" v-model="email"/>
                                <b-input-group-append is-text>
                                    <font-awesome-icon :icon="['fas', 'at']"/>
                                </b-input-group-append>
                            </b-input-group>
                            <br>
                            <b-input-group prepend="Password">
                                <b-form-input type="password" v-model="password"/>
                                <b-input-group-append is-text>
                                    <font-awesome-icon :icon="['fas', 'shield-alt']"/>
                                </b-input-group-append>
                            </b-input-group>
                            <div v-if="loginError" class="mt-3 login-error">{{ loginError }}</div>
                        </b-form>
                    </b-card-footer>
                    <b-card-footer class="text-center">
                        <b-button pill variant="success" class="pl-5 pr-5" :disabled="!email || !password"
                                  v-on:click="login">
                            {{ $i18n.t('translations.Login') }}
                        </b-button>
                    </b-card-footer>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginDialog",
    data() {
        return {
            email: '',
            password: '',
            loginError: null,
            toolName: process.env.VUE_APP_NAME,
        }
    },
    mounted() {
        this.$refs.email.focus();
    },
    methods: {
        login() {
            this.loginError = null;
            this.$http.post('/auth/login', {email: this.email, password: this.password}, {}).then((response) => {
                this.$emit('login-successfull', response.data);
            }).catch(() => {
                this.loginError = 'Your email or password is not correct';
            });
        }
    }
}
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99900;
    backdrop-filter: blur(10px);
    background: rgba(25, 25, 25, 0.6);
    overflow: auto;
}

.login {
    width: 450px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.login-error {
    color: red;
    text-align: center;
    margin-bottom: 15px;
}
</style>
