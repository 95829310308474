<template>
    <div id="wrapper" :key="userId + '-' + userLanguage" :class="{'d-flex': true, 'toggled': !this.showSidebar}">
        <LoginDialog v-if="!accessTokenValid" v-on:login-successfull="logIn"/>

        <ChangePasswordDialog v-if="accessTokenValid" v-bind="changePasswordDialogParameters"
                              v-on:password-changed="logOut"/>

        <RecordDialog v-if="showRecordDialog" v-bind="recordDialogParameters"
                      v-on:hidden="showRecordDialog = false"/>

        <div id="overlay-wrapper" v-if="state.loading">
            <div class="st-spinner spinner-border" role="status"/>
        </div>

        <template v-if="frameWorkLoaded">

            <!--
                    SIDEBAR
            -->
            <div id="sidebar-wrapper">
                <SideBar :accessTokenValid="accessTokenValid"/>
            </div>

            <!--
                    PAGE CONTENT
            -->
            <div id="page-content-wrapper">

                <!--
                        HEADER
                -->
                <b-navbar id="header-wrapper" class="st-header" fixed="top">

                    <b-button id="toggle-sidebar" size="sm" variant="light" v-on:click="toggleSidebar">
                        <font-awesome-icon :icon="['fas', 'bars']" style="color: #636f83;"/>
                    </b-button>

                    <b-navbar-nav style="position: absolute; left: 50%; transform: translateX(-50%);">
                        <img src="@/assets/reijrink.jpg" alt="reijrink-logo" height="35" width="auto" v-if="application === 'REIJRINK'"/>
                        <img src="@/assets/rodenburg.jpg" alt="rodenburg-logo" height="40" width="auto" v-else-if="application === 'RODENBURG'"/>
                        <p style="font-size: 3em; font-weight: bold; color: red; padding-top: 15px;" v-else-if="application === 'TEST'">TEST.SKELLET.COM</p>
                        <img src="@/assets/skellet.jpg" alt="skellet-logo" height="35" width="auto" v-else-if="application === 'TOOL'"/>
                    </b-navbar-nav>

                    <b-navbar-nav class="ml-auto">
                        <b-dropdown toggle-class="dark" :text="userLanguage" size="sm" right>
                            <template v-for="language in availableLanguages">
                                <b-dropdown-item-button :key="'language-' + language" v-on:click="switchLanguage(language)">
                                    {{ language }}
                                </b-dropdown-item-button>
                            </template>
                        </b-dropdown>
                        <b-dropdown toggle-class="dark" :text="userName" size="sm" right class="ml-1">
                            <b-dropdown-item-button v-on:click="changePassword">
                                <font-awesome-icon :icon="['fas', 'shield-alt']" class="mr-2"/>
                                {{ $i18n.t('translations.Change Password') }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button v-on:click="updateNotifications">
                                <font-awesome-icon :icon="['fas', 'bell']" class="mr-2"/>
                                {{ $i18n.tc('translations.Notification', 2) }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button v-on:click="updateUserSettings">
                                <font-awesome-icon :icon="['fas', 'cogs']" class="mr-2"/>
                                {{ $i18n.t('translations.Settings') }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button v-on:click="logOut">
                                <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="mr-2"/>
                                {{ $i18n.t('translations.Logout') }}
                            </b-dropdown-item-button>
                        </b-dropdown>
                    </b-navbar-nav>

                </b-navbar>

                <!--
                    ROUTER VIEW
                -->
                <div v-if="accessTokenValid">
                    <router-view :state="state" :userLanguage="userLanguage" :languages="languages" :roles="userRoles" :permissions="userPermissions"/>
                </div>

                <!--
                    FOOTER
                -->
                <div id="footer-wrapper">
                    <div style="display: inline-block; padding: .4rem 1rem;">
                        <b>{{ environment }} ({{ database }})</b> -
                        <b>Frontend:</b> {{ realFrontendTimestamp }} ({{ realFrontendVersion }}/{{ frontendVersion }}) -
                        <b>Backend:</b> {{ backendTimestamp }} ({{ realBackendVersion }}/{{ backendVersion }})
                    </div>
                    <div class="float-right" style="display: inline-block; padding: 0 1rem;" v-if="frontendBackendVersionMismatch">
                        <button id="version-mismatch-button" type="button" class="btn btn-sm btn-danger">VERSION MISMATCH</button>
                        <b-popover target="version-mismatch-button" triggers="hover focus click" placement="top" variant="danger">
                            <template #title>Version Mismatch</template>
                            There is a version mismatch between your browser cache and the server! This is most likely caused by a software update on the {{ toolName }} server.<br>
                            To fix this, please refresh your browser cache: press CTRL+F5 for windows or Command+Shift+R for mac.
                        </b-popover>
                    </div>
                    <div class="float-right" style="display: inline-block; padding: 0 1rem;" v-else-if="outdatedSketchUpPlugin">
                        <button type="button" class="btn btn-sm btn-danger" v-on:click="openSketchUpExtensionManager">
                            {{ $i18n.tc('translations.Upgrade the SketchUp Plugin to version', 1) }} {{ sketchUpPluginVersion }}
                        </button>
                    </div>
                </div>

            </div>

        </template>
        <template v-else-if="incompatibleSketchUpPlugin">
            <div class="card shadow ml-auto mr-auto">
                <div class="card-body">
                    <div>{{ $i18n.t("translations.incompatibleSketchUpPluginVersion", {version: this.sketchUpPluginVersion}) }}</div>
                </div>
                <div class="card-footer light" style="text-align: center;">
                    <button type="button" class="btn btn-sm btn-danger" v-on:click="openSketchUpExtensionManager">Open Extension Manager</button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ChangePasswordDialog from "@/components/dialogs/ChangePasswordDialog";
import LoginDialog from "@/components/dialogs/LoginDialog";
import RecordDialog from "@/components/dialogs/RecordDialog";
import SideBar from "@/components/SideBar";
import version from '../../.version.json';

/* global sketchup:false */

export default {
    name: 'FrameWork',
    components: {
        ChangePasswordDialog,
        LoginDialog,
        RecordDialog,
        SideBar,
    },
    data() {
        return {
            toolName: process.env.VUE_APP_NAME,
            sketchUpBrowser: (typeof sketchup !== "undefined"),
            frameWorkLoaded: false,
            state: {
                loading: false,
                disabled: false,
                readonly: false,
            },
            accessTokenValid: true,
            userId: null,
            userName: '',
            userRoles: null,
            userPermissions: null,
            userLanguage: '',
            systemLanguage: 'en',
            languages: [],
            application: '',
            environment: '',
            database: '',
            sketchUpPluginVersion: '',
            incompatibleSketchUpPlugin: false,
            outdatedSketchUpPlugin: false,
            frontendVersion: '',
            frontendTimestamp: '',
            backendVersion: '',
            backendTimestamp: '',
            realFrontendVersion: '',
            realFrontendTimestamp: '',
            realBackendVersion: '',
            realBackendTimestamp: '',
            frontendBackendVersionMismatch: false,
            changePasswordDialogParameters: {
                api: '',
            },
            showRecordDialog: false,
            recordDialogParameters: {
                type: null,
                title: null,
                api: null,
                fields: null,
                data: null,
                languages: this.languages,
                userLanguage: this.userLanguage,
            },
            showSidebar: true,
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.state.loading = true;
        this.state.disabled = false;
        this.state.readonly = false;
        next();
    },
    computed: {
        availableLanguages: function () {
            let l = Array.from(this.languages);
            l.splice(l.indexOf(this.userLanguage), 1);
            return l;
        },
    },
    beforeMount() {
        if (process.env.VUE_APP_DEBUG === "1") {
            console.log("FrameWork:beforeMount():this.$cookies.keys():", this.$cookies.keys());
        }

        this.$i18n.locale = "en";

        if (this.$cookies.isKey('accessToken')) {
            this.accessTokenValid = true;
            this.refreshAccessToken();
        } else {
            this.accessTokenValid = false;
        }
    },
    created() {
        window.vm.ToolFrameWork = this;
    },
    methods: {
        changePassword() {
            this.changePasswordDialogParameters.api = '/users/change_password';
            this.$bvModal.show('change-password-dialog');
        },
        clearAccessToken() {
            this.frameWorkLoaded = false;
            this.userId = null;
            this.accessTokenValid = false;
            this.$cookies.remove("accessToken");
        },
        getSketchUpPluginVersionCallback(version) {
            let currentMajorVersion = version.substring(0, version.lastIndexOf('.'));
            let requiredMajorVersion = this.sketchUpPluginVersion.substring(0, this.sketchUpPluginVersion.lastIndexOf('.'));
            if (currentMajorVersion !== requiredMajorVersion) {
                this.incompatibleSketchUpPlugin = true;
                this.state.loading = false;
                return;
            }

            this.outdatedSketchUpPlugin = (this.sketchUpPluginVersion !== version);
            this.$http.post('/users/set_sketchup_plugin_version/' + this.userId, {
                'sketchup_plugin_version': version,
            }, {}).catch((error) => {
                console.log("FrameWork:setSketchUpPluginVersion():error:", error);
            });

            this.frameWorkLoaded = true;
        },
        logIn(data) {
            this.setUserData(data);
            if (this.$route.name !== 'dashboard') {
                this.$router.push({name: 'dashboard'});
            }
        },
        logOut() {
            if (this.accessTokenValid) {
                this.$http.post('/auth/logout', {}, {}).then((res) => {
                    if (process.env.VUE_APP_DEBUG === "1") {
                        console.log("FrameWork:logOut():res:", res);
                    }
                }).catch((error) => {
                    console.log("FrameWork:logOut():error:", error);
                });
                this.clearAccessToken();
            }
        },
        openSketchUpExtensionManager() {
            sketchup.openSketchUpExtensionManager();
        },
        refreshAccessToken() {
            this.$http.get('/auth/refresh', {}).then((res) => {
                if (process.env.VUE_APP_DEBUG === "1") {
                    console.log("FrameWork:refreshAccessToken():res:", res);
                }
                this.setUserData(res.data);
            }).catch((error) => {
                this.clearAccessToken();
                console.log("FrameWork:refreshAccessToken():error:", error);
            });
        },
        setUserData(userData) {
            this.$i18n.locale = userData.user.language;

            this.accessTokenValid = true;
            this.$cookies.set("accessToken", userData.jwt.access_token, Infinity);

            this.userId = userData.user.id;
            this.userName = userData.user.name;
            this.userRoles = userData.user.roles;
            this.userPermissions = userData.user.permissions;
            this.userLanguage = userData.user.language;

            this.systemLanguage = userData.config.system_language;
            this.languages = userData.config.languages;
            this.application = userData.config.application;
            this.environment = userData.config.environment;
            this.database = userData.config.database;

            this.sketchUpPluginVersion = userData.config.sketchup_plugin_version;
            this.frontendVersion = userData.config.frontend_version;
            this.frontendTimestamp = userData.config.frontend_timestamp;
            this.backendVersion = userData.config.backend_version;
            this.backendTimestamp = userData.config.backend_timestamp;

            this.realFrontendVersion = version.frontend.version;
            this.realFrontendTimestamp = version.frontend.timestamp;
            this.realBackendVersion = version.backend.version;
            this.realBackendTimestamp = version.backend.timestamp;

            this.frontendBackendVersionMismatch = (this.realFrontendVersion !== this.frontendVersion) || (this.realBackendVersion !== this.backendVersion);

            if (this.sketchUpBrowser) {
                sketchup.getSketchUpPluginVersion();
            } else {
                this.frameWorkLoaded = true;
            }
        },
        switchLanguage(language) {
            this.$http.post('/users/switch_language/' + this.userId, {
                'language': language,
            }, {}).then((res) => {
                if (process.env.VUE_APP_DEBUG === "1") {
                    console.log("FrameWork:switchLanguage():res:", res);
                }
                this.userLanguage = res.data.data.language;
                this.$i18n.locale = this.userLanguage;
            }).catch((error) => {
                console.log("FrameWork:switchLanguage():error:", error);
            });
        },
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
        },
        updateNotifications() {
            this.$http.get('/notifications/subscriptions', {}).then((res) => {
                this.recordDialogParameters.type = "update";
                this.recordDialogParameters.title = this.$i18n.tc("translations.Notification", 2);
                this.recordDialogParameters.api = "/notifications/update_subscriptions";
                this.recordDialogParameters.fields = res.data.fields;
                this.recordDialogParameters.data = res.data.data;
                this.recordDialogParameters.ok_event = 'ok';
                this.showRecordDialog = true;
            }).catch((error) => {
                console.log("FrameWork:updateNotifications():error:", error);
            });
        },
        updateUserSettings() {
            this.$http.get('/users/settings', {}).then((res) => {
                this.recordDialogParameters.type = "update";
                this.recordDialogParameters.title = this.$i18n.t("translations.Settings");
                this.recordDialogParameters.api = "/users/update_settings";
                this.recordDialogParameters.fields = res.data.fields;
                this.recordDialogParameters.data = res.data.data;
                this.recordDialogParameters.ok_event = 'ok';
                this.showRecordDialog = true;
            }).catch((error) => {
                console.log("FrameWork:updateUserSettings():error:", error);
            });
        }
    }
}
</script>

<style scoped>
#sidebar-wrapper {
    height: 100vh;
    width: 240px;
    background-color: #636f83;
    overflow-x: hidden;
    overflow-y: auto;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: -240px;
}

#header-wrapper {
    height: 50px;
    margin-left: 240px;
    color: #3c4b64;
    background-color: white;
}

#wrapper.toggled #header-wrapper {
    margin-left: 0;
}

#sidebar-wrapper, #header-wrapper {
    -webkit-transition: margin .25s;
    -moz-transition: margin .25s;
    -o-transition: margin .25s;
    transition: margin .25s;
}

#page-content-wrapper {
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100vh - 50px);
    width: calc(100vw - 240px);
    margin-top: 50px;
}

#wrapper.toggled #page-content-wrapper {
    width: 100vw;
}

#page-content-wrapper {
    -webkit-transition: width .25s;
    -moz-transition: width .25s;
    -o-transition: width .25s;
    transition: width .25s;
}

#overlay-wrapper {
    z-index: 99999 !important;
    position: fixed;
    left: 240px;
    width: calc(100vw - 240px);
    top: 50px;
    height: calc(100vh - 50px);
    display: flex;
    backdrop-filter: brightness(90%);
}

#overlay-wrapper .st-spinner {
    position: fixed;
    top: 30%;
    left: calc(50% - 1rem);
}

#wrapper.toggled #overlay-wrapper {
    left: 0;
    width: 100vw;
}

#footer-wrapper {
    color: #3c4b64;
    background-color: white;
}
</style>