<template>
    <div v-if="pageLoaded">
        <template v-for="(item,index) in sideBarMenu">
            <template v-if="!item.sketchup || sketchUpBrowser">
                <template v-if="item.type === 'SideBarDropDown'">
                    <SideBarDropDown :key="'dropdown-' + item.type + '-' + index" :name="item.name" :icon="item.icon" :route="item.route" :children="item.children"/>
                </template>
                <template v-else-if="item.type === 'SideBarItem'">
                    <SideBarItem :key="'item-' + item.type + '-' + index" :name="item.name" :icon="item.icon" :to="item.to"/>
                </template>
                <template v-else-if="item.type === 'SideBarTitle'">
                    <SideBarTitle :key="'title-' + item.type + '-' + index" :name="item.name" :icon="item.icon"/>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
import SideBarDropDown from "@/components/sidebar/SideBarDropDown";
import SideBarItem from "@/components/sidebar/SideBarItem";
import SideBarTitle from "@/components/sidebar/SideBarTitle";

/* global sketchup:false */

export default {
    name: "SideBar",
    props: ["accessTokenValid"],
    components: {
        SideBarDropDown,
        SideBarItem,
        SideBarTitle,
    },
    data() {
        return {
            pageLoaded: false,
            sketchUpBrowser: (typeof sketchup !== "undefined"),
            sideBarMenu: null,
        }
    },
    created() {
        if (this.accessTokenValid) {
            this.$http.get('/vue/sidebar', {}).then((res) => {
                this.sideBarMenu = res.data;
                this.pageLoaded = true;
            }).catch((error) => {
                console.log("SideBar:created():error:", error);
            });
        }
    }
}
</script>

<style scoped>

</style>
