<template>
    <b-modal id="change-password-dialog" size="md"
             no-close-on-esc
             no-close-on-backdrop
             v-on:cancel="$emit('cancel')"
             v-on:show="init">

        <template #modal-title>
            <font-awesome-icon :icon="['fas', 'shield-alt']"/>
            <span class="ml-3">{{ $i18n.t("translations.Change Password") }}</span>
        </template>

        <b-form v-on:keyup.enter="changePassword">
            <b-input-group :prepend="$i18n.t('translations.Current Password')">
                <b-form-input type="password" ref="current_password" v-model="currentPassword" onClick="this.select();"/>
                <b-input-group-append is-text>
                    <font-awesome-icon :icon="['fas', 'shield-alt']"/>
                </b-input-group-append>
            </b-input-group>
            <div v-if="changePasswordErrors && changePasswordErrors.current_password" class="changePassword-error">{{ changePasswordErrors.current_password[0] }}</div>
            <br>
            <b-input-group :prepend="$i18n.t('translations.New Password')">
                <b-form-input type="password" v-model="password" onClick="this.select();"/>
                <b-input-group-append is-text>
                    <font-awesome-icon :icon="['fas', 'shield-alt']"/>
                </b-input-group-append>
            </b-input-group>
            <div v-if="changePasswordErrors && changePasswordErrors.password" class="changePassword-error">{{ changePasswordErrors.password[0] }}</div>
            <br>
            <b-input-group :prepend="$i18n.t('translations.Repeat Password')">
                <b-form-input type="password" v-model="passwordConfirmation" onClick="this.select();"/>
                <b-input-group-append is-text>
                    <font-awesome-icon :icon="['fas', 'shield-alt']"/>
                </b-input-group-append>
            </b-input-group>
            <div v-if="changePasswordErrors && changePasswordErrors.password_confirmation" class="changePassword-error">{{ changePasswordErrors.password_confirmation[0] }}</div>
        </b-form>

        <template #modal-footer="{ cancel }">
            <b-button pill variant="danger" class="pl-5 pr-5 mr-2" :disabled="!currentPassword || !password || currentPassword === password || password!==passwordConfirmation"
                      v-on:click="changePassword">
                {{ $i18n.t("translations.Change Password") }}
            </b-button>
            <b-button pill variant="dark" class="pl-5 pr-5"
                      v-on:click="cancel()">
                {{ $i18n.t("translations.Cancel") }}
            </b-button>
        </template>

    </b-modal>
</template>

<script>
export default {
    name: "ChangePasswordDialog",
    props: {
        api: {type: String, default: ''},
    },
    data() {
        return {
            currentPassword: '',
            password: '',
            passwordConfirmation: null,
            changePasswordErrors: null,
        }
    },
    methods: {
        changePassword() {
            this.$http.post(this.api, {
                current_password: this.currentPassword,
                password: this.password,
                password_confirmation: this.passwordConfirmation,
            }, {}).then(() => {
                this.$emit('password-changed');
                this.$bvModal.hide('change-password-dialog');
            }).catch((error) => {
                this.changePasswordErrors = error.response.data.errors;
            });
        },
        init() {
            this.currentPassword = '';
            this.password = '';
            this.passwordConfirmation = null;
            this.changePasswordErrors = null;
        }
    }
}
</script>

<style scoped>
.changePassword-error {
    color: red;
    text-align: center;
    margin-bottom: 15px;
}
</style>
